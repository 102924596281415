import React from "react"
import "../assets/styles/components/eventscard.scss"

const eventscard = (props: { eventsImage: any; season: any; title: any; description: any }) => {
  const { eventsImage, season, title, description } = props
  return (
    <div>
      <div className="event--wrapper">
        <div className="event--image--wrapper">
          <img src={eventsImage} alt="" />
        </div>
        <div className="event--text--wrapper">
          <div className="event--text--inner">
            <h2 className="event--season">{season}</h2>
            <p className="event--title">{title}</p>
            <p className="event--description">{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default eventscard
